import apiClient from 'services/axios'

const BASE_URL = 'api/vendor'
const BASE_URL2 = 'api/individual-vendor'

export async function loadAll() {
  return apiClient
    .get(`${BASE_URL}/vendor-list`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadsinglevendor(payload) {
  return apiClient
    .get(`${BASE_URL2}/individual-vendor-detail/${payload?.vendorId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadsinglevendorservice(payload) {
  return apiClient
    .get(`${BASE_URL2}/individual-vendor-service-detail/${payload?.vendorId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}



export async function create(payload) {
  return apiClient
    .post(`${BASE_URL}/add-vendor`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function update(payload) {
  return apiClient
    .post(`${BASE_URL}/edit-vendor`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function remove(vendorId) {
  return apiClient
    .put(`${BASE_URL}/delete-vendor/${vendorId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
