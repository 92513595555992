import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Button, InputNumber, Form, Select, DatePicker, Modal } from 'antd'
import { createStructuredSelector } from 'reselect'
import { selectDriver, selectedDriverdata } from 'redux/driver/selectors'
import { selectVehicle } from 'redux/vehicle/selectors'
import VehicleAction from 'redux/vehicle/actions'
import { fuelvariant } from 'utils/data'
import { selectSettings } from 'redux/settings/selectors'
import moment from 'moment'



const { Option } = Select

const mapStateToProps = () =>
  createStructuredSelector({
    driver: selectDriver,
    vehicle: selectVehicle,
    selectedDriver: selectedDriverdata,
    settings: selectSettings,
  })

const ChangeAccountManager = ({
  visible,
  vehicle,
  onCancel,
  loading,
  title,
  intl: { formatMessage },
}) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const onSubmit = values => {

    const formData = {
      "fuel_variant": values?.fuel_variant,
      "kilometers": values?.kilometers,
      "amount": values?.amount,
      "vehicleId": vehicle?.selectedData?.vehicle,
      "fuelId": vehicle?.selectedData?._id,
      "receipt_date": values?.receipt_date.format('YYYY-MM-DD'),
    }
    dispatch({
      type: VehicleAction.UPDATE_FUEL_INFO,
      payload: {
        payloadReq: formData
      }
    })
  }

  const onChange2 = (date, dateString) => {
    console.log(date, dateString)
  }

  useEffect(() => {
    if (vehicle?.selectedData) {
      form.setFieldsValue({
        fuel_variant: vehicle?.selectedData?.fuel_variant,
        kilometers: vehicle?.selectedData?.kilometers,
        amount: vehicle?.selectedData?.amount,
        receipt_date: moment(vehicle?.selectedData?.receipt_date),
      })
    } // eslint-disable-next-line
  }, [form, vehicle])

  return (
    <Modal title={title} visible={visible} onCancel={onCancel} footer={null}>
      <Form form={form} layout="vertical" hideRequiredMark onFinish={onSubmit}>
        <Form.Item
          name="fuel_variant"
          label={formatMessage({ id: 'form.label.fuel_variant' })}
          rules={[{ required: true, message: formatMessage({ id: 'form.error.required' }) }]}
        >
          <Select
            showSearch
            placeholder={formatMessage({ id: 'form.placeholder.fuel_variant' })}
            size="large"
          >
            {fuelvariant?.map(o => (
              <Option key={o.key} value={o.key}>
                {o.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="receipt_date"
          label={formatMessage({ id: 'form.label.receipt_date' })}
          rules={[{ required: true, message: formatMessage({ id: 'form.error.required' }) }]}
        >
          <DatePicker style={{ width: '100%' }} onChange={onChange2} />
        </Form.Item>

        <Form.Item
          name="kilometers"
          label={formatMessage({ id: 'form.label.kilometers' })}
          rules={[{ required: true, message: formatMessage({ id: 'form.error.required' }) }]}
        >
          <InputNumber
            style={{ width: '100%' }}
            size="large"
            placeholder={formatMessage({ id: 'form.placeholder.kilometers' })}
            maxLength={8}
          />
        </Form.Item>
        <Form.Item
          name="amount"
          label={formatMessage({ id: 'form.label.amount' })}
          rules={[{ required: true, message: formatMessage({ id: 'form.error.required' }) }]}
        >
          <InputNumber
            style={{ width: '100%' }}
            size="large"
            placeholder={formatMessage({ id: 'form.placeholder.amount' })}
            maxLength={5}
          />
        </Form.Item>
        <br />
        <Form.Item className="text-right">
          <Button type="primary" htmlType="submit" size="large" loading={loading}>
            {formatMessage({ id: 'action.Update' })}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default connect(mapStateToProps)(injectIntl(ChangeAccountManager))
