import React, { Suspense, useState } from 'react'
import { connect } from 'react-redux'
import { Table, Space, Spin, Tag } from 'antd'
import { injectIntl } from 'react-intl'
import { getFormattedDate } from 'utils/parser'

const mapStateToProps = ({ dispatch, vehicle }) => ({
  dispatch,
  vehicle,
})

const StoreSettings = ({ apiRes, intl: { formatMessage } }) => {
  const [page, setPage] = useState(1)

  const onExportClick = (values) => {
    window.open(values)
  }

  const columns = [
    {
      title: formatMessage({ id: 'text.serialNo' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.service_reuest_id' }),
      render: (text, record) => (
        <>
          <Tag
            color={(() => {
              switch (text?.service_reuest_id) {
                default:
                  return 'blue'
              }
            })()}
            style={{ textTransform: 'capitalize' }}
          >
            {record?.service_reuest_id}
          </Tag>
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.vendorDetails' }),
      render: text => <>{`${text?.vendor_name} - ${text?.vendor_city} `}</>,
    },
    {
      title: formatMessage({ id: 'text.amount' }),
      dataIndex: 'billamount',
      key: 'billamount',
      render: (text, record) => <>{record?.billamount} INR</>,
    },

    {
      title: formatMessage({ id: 'text.receipt_date' }),
      dataIndex: 'service_end_date',
      key: 'service_end_date',
      render: text => getFormattedDate(text),
    },
    {
      title: formatMessage({ id: 'text.action' }),
      key: 'action',
      render: (record, text) => (
        <Space size="middle">
          <button type="button" className="btn btn-light mb-3 mb-md-0" onClick={() => onExportClick(text?.document_url)} hidden={text?.document === null}>
            <i className="fe fe-download mr-1" aria-hidden="true" />
          </button>
        </Space>
      ),
    },
    // {
    //   title: formatMessage({ id: 'text.ViewUrl' }),
    //   render: (text, record) => (
    //     <>
    //       <button type="button" className="btn btn-light" onClick={() => onViewStoreClick(record)}>
    //         <i className="fe fe-eye mr-1" aria-hidden="true" />
    //         {formatMessage({ id: 'action.viewStore' })}
    //       </button>
    //     </>
    //   ),
    // },
  ]

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.Expensesdata' }),
  }

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="mb-4">
            <div className="kit__utils__table">
              <Suspense fallback={<Spin />}>
                {apiRes?.length >= 0 ? (
                  <Table
                    dataSource={apiRes}
                    columns={columns}
                    locale={locale}
                    rowKey="_id"
                    pagination={{
                      onChange(current) {
                        setPage(current)
                      },
                    }}
                  />
                ) : (
                  <Table
                    columns={columns}
                    locale={locale}
                    loading={{
                      indicator: (
                        <div>
                          <Spin />
                        </div>
                      ),
                    }}
                  />
                )}
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(StoreSettings))
