import actions from './actions'

const initialState = {
  servicesInfo: null,
  ServicesInfodata: null,
  data: null,
  roledata: null,
  selectedData: null,
  newlyAdded: null,
  serviceDetail: null,
  serviceHistory: null,
  tyreSerialNoInfo: null,

  searchServices: null,
  displayAddDrawer: false,
  displayPopupModal: false,
  displayModifyPopupModal: false,
  displayViewDrawer: false,
  displayUploadModal: false,
}

export default function servicesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_CREATE:
      return { ...state, displayAddDrawer: true }
    case actions.HIDE_CREATE:
      return { ...state, displayAddDrawer: false }

    case actions.HIDE_MODIFY_POPUP:
      return { ...state, displayModifyPopupModal: false }



    case actions.SHOW_POPUP:
      return { ...state, selectedData: action.payload, displayPopupModal: true }
    case actions.HIDE_POPUP:
      return { ...state, displayPopupModal: false }
    case actions.SHOW_DETAIL:
      return { ...state, selectedData: action.payload, displayViewDrawer: true }
    case actions.HIDE_DETAIL:
      return { ...state, displayViewDrawer: false }
    case actions.SHOW_UPLOAD:
      return { ...state, selectedData: action.payload.serviceInfo, displayUploadModal: true }
    case actions.HIDE_UPLOAD:
      return { ...state, displayUploadModal: false }
    default:
      return state
  }
}
