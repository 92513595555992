import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  create,
  loadAll,
  remove,
  update,
  updatedriver,
  loadsinglevehicle,
  loadsingledriver,
  loadsingleservice,
  loadsinglevehicledocs,
  loadsinglefuel,
  loadfilter,
  loadreport,
  removedocument,
  loadvehicleexpenses,updateFuelInfo
} from 'api/vehicle'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadAll)
  if (response) {
    const { vehicleList } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        VehicleInfodata: vehicleList,
      },
    })
  }
}

export function* CREATE({ payload }) {
  const { shouldAdd, ...formValues } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(create, formValues)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    const { vehicleList } = response.data
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        VehicleInfodata: vehicleList,
      },
    })
  }
}

export function* UPDATE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  // const { UseridSel, updatedData } = payload
  const { response } = yield call(update, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    const { vehicleList } = response.data
    yield put({
      type: actions.HIDE_UPDATE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        VehicleInfodata: vehicleList,
      },
    })
  }
}

export function* REMOVE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })
  const { _id } = payload
  const { response } = yield call(remove, _id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: false,
    },
  })
  if (response) {
    const { vehicleList } = response.data
    yield put({
      type: actions.HIDE_DELETE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        VehicleInfodata: vehicleList,
      },
    })
  }
}

export function* REMOVE_DOCUMENT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })
  const { _id } = payload
  const { response } = yield call(removedocument, _id)
  if (response)
    yield put({
      type: actions.SET_STATE,
      payload: {
        removing: false,
      },
    })
  yield put({
    type: actions.HIDE_DOC_DRAWER,
  })
  yield call(LOAD_ALL)
}

export function* UPDATE_DRIVER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(updatedriver, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    const { vehicleList } = response.data
    yield put({
      type: actions.HIDE_LINK_DRIVER,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        VehicleInfodata: vehicleList,
      },
    })
  }
}

export function* LOAD_SINGLE_VEHICLE({ payload }) {
  const { response } = yield call(loadsinglevehicle, payload)
  if (response) {
    const { IndividualVehicleDetail } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        IndividualVehicleInfodata: IndividualVehicleDetail,
      },
    })
  }
}

export function* LOAD_SINGLE_DRIVER({ payload }) {
  const { response } = yield call(loadsingledriver, payload)
  if (response) {
    const { IndividualDriverDetail } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        IndividualDriverInfodata: IndividualDriverDetail,
      },
    })
  }
}

export function* LOAD_SINGLE_VEHICLE_DOCUMENT({ payload }) {
  const { response } = yield call(loadsinglevehicledocs, payload)
  if (response) {
    const { IndividualvehicleDocumentDetail } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        IndividualVehicleDocumentsInfodata: IndividualvehicleDocumentDetail,
      },
    })
  }
}

export function* LOAD_SINGLE_SERVICE({ payload }) {
  const { response } = yield call(loadsingleservice, payload)
  if (response) {
    const { IndividualServiceDetail } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        IndividualServiceInfodata: IndividualServiceDetail,
      },
    })
  }
}

export function* LOAD_SINGLE_FUEL({ payload }) {
  const { response } = yield call(loadsinglefuel, payload)
  if (response) {
    const { IndividualFuelDetail } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        IndividualFuelInfodata: IndividualFuelDetail,
      },
    })
  }
}



export function* LOAD_SINGLE_VEHICLE_EXPENSES({ payload }) {
  const { response } = yield call(loadvehicleexpenses, payload)
  if (response) {
    const { IndividualExpensesDetail } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        IndividualExpensesInfodata: IndividualExpensesDetail,
      },
    })
  }
}




export function* LOAD_REPORT({ payload }) {
  const { response } = yield call(loadreport, payload)
  if (response) {
    const { reportInfo, filterVal } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        serviceReport: reportInfo,
        filterDetail: filterVal,
      },
    })
  }
}

export function* SERVICE_FILTER({ payload }) {
  const { response } = yield call(loadfilter, payload)
  if (response) {
    const { IndividualServiceDetail } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        IndividualServiceInfodata: IndividualServiceDetail,
      },
    })
  }
}


export function* UPDATE_FUEL_INFO({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updatingFuel: true,
    },
  })
  const { response } = yield call(updateFuelInfo, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updatingFuel: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_FUEL_UPDATE,
    })
    yield call(LOAD_ALL)
  }
}




export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.REMOVE, REMOVE),
    takeEvery(actions.UPDATE_DRIVER, UPDATE_DRIVER),
    takeEvery(actions.LOAD_SINGLE_VEHICLE, LOAD_SINGLE_VEHICLE),
    takeEvery(actions.LOAD_SINGLE_DRIVER, LOAD_SINGLE_DRIVER),
    takeEvery(actions.LOAD_SINGLE_SERVICE, LOAD_SINGLE_SERVICE),
    takeEvery(actions.LOAD_SINGLE_VEHICLE_DOCUMENT, LOAD_SINGLE_VEHICLE_DOCUMENT),
    takeEvery(actions.LOAD_SINGLE_FUEL, LOAD_SINGLE_FUEL),
    takeEvery(actions.SERVICE_FILTER, SERVICE_FILTER),
    takeEvery(actions.LOAD_REPORT, LOAD_REPORT),
    takeEvery(actions.REMOVE_DOCUMENT, REMOVE_DOCUMENT),
    takeEvery(actions.LOAD_SINGLE_VEHICLE_EXPENSES, LOAD_SINGLE_VEHICLE_EXPENSES),
    takeEvery(actions.UPDATE_FUEL_INFO, UPDATE_FUEL_INFO),
  ])
}
