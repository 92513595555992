import apiClient from 'services/axios'

const BASE_URL = 'api/fuel'

export async function loadAll() {
  return apiClient
    .get(`${BASE_URL}/fuel-list`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function create(payload) {
  return apiClient
    .post(`${BASE_URL}/add-fuel`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}


export async function update(payload) {

  return apiClient
    .post(`${BASE_URL}/update-fuel`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function remove(staffId) {
  return apiClient
    .put(`${BASE_URL}/fuel-record-delete/${staffId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function removedocuments(staffId) {
  console.log("staffId", staffId)
  return apiClient
    .put(`${BASE_URL}/remove-fuel-documents/${staffId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}



export async function getdocuments(staffId) {
  return apiClient
    .put(`${BASE_URL}/get-fuel-documents/${staffId?.payload?.fuelId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}


export async function sampleupload(payload) {

  return apiClient
    .post(`${BASE_URL}/upload-fuel`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
