import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Input, Form, Button, DatePicker, Switch } from 'antd'
import { injectIntl } from 'react-intl'
import MaskedInput from 'antd-mask-input'
import VehicleAction from 'redux/vehicle/actions'
import { phoneNumberE164, phoneNumberNational } from 'utils/parser'
import CardTitle from 'components/app/common/CardTitle'
import moment from 'moment'

const { TextArea } = Input

const mapStateToProps = ({ dispatch, vehicle }) => ({
  dispatch,
  vehicle,
})

const StoreSettings = ({ dispatch, apiRes, vehicle, intl: { formatMessage } }) => {
  const AccountDatas = apiRes

  const ApiDatas = AccountDatas

  const onFinish = values => {
    if (values.mobile_number) values.mobile_number = phoneNumberE164(values.mobile_number)
    const requestPayload = {
      driver_name: values?.driver_name,
      license_no: values?.license_no,
      aadhaar_no: values?.aadhaar_no,
      address: values?.address,
      dob: values?.dob.format('YYYY-MM-DD'),
      doi: values?.doi.format('YYYY-MM-DD'),
      valid_till: values?.valid_till.format('YYYY-MM-DD'),
      status: values?.status,
    }
    dispatch({
      type: VehicleAction.UPDATE,
      payload: {
        updatedData: requestPayload,
        driverId: ApiDatas?._id,
      },
    })
  }

  const [form] = Form.useForm()

  const onChange1 = (date, dateString) => {
    console.log(date, dateString)
  }

  const onChange2 = (date, dateString) => {
    console.log(date, dateString)
  }

  const onChange3 = (date, dateString) => {
    console.log(date, dateString)
  }

  useEffect(() => {
    if (AccountDatas) {
      form.setFieldsValue({
        dob: moment(ApiDatas?.dob),
        doi: moment(ApiDatas?.doi),
        valid_till: moment(ApiDatas?.valid_till),
        mobile_number: phoneNumberNational(ApiDatas?.mobile_number),
        driver_name: ApiDatas?.driver_name,
        license_no: ApiDatas?.license_no,
        aadhaar_no: ApiDatas?.aadhaar_no,
        address: ApiDatas?.address,
        status: ApiDatas?.status,
      })
    } // eslint-disable-next-line
  }, [form, AccountDatas])

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <Form
            hidden={!ApiDatas}
            layout="vertical"
            hideRequiredMark
            onFinish={onFinish}
            form={form}
            initialValues={{
              dob: moment(ApiDatas?.dob),
              doi: moment(ApiDatas?.doi),
              valid_till: moment(ApiDatas?.valid_till),
              mobile_number: phoneNumberNational(ApiDatas?.mobile_number),
              driver_name: ApiDatas?.driver_name,
              license_no: ApiDatas?.license_no,
              aadhaar_no: ApiDatas?.aadhaar_no,
              address: ApiDatas?.address,
              status: ApiDatas?.status,
            }}
          >
            <CardTitle title={formatMessage({ id: 'text.DriverDetails' })} />
            <div className="row">
              <div className="col-md-6">
                <Form.Item
                  name="driver_name"
                  label={formatMessage({ id: 'form.label.driver_name' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.driver_name' })}
                    maxLength={64}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="license_no"
                  label={formatMessage({ id: 'form.label.license_no' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.license_no' })}
                    maxLength={64}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="mobile_number"
                  label={formatMessage({ id: 'form.label.phoneoptional' })}
                  rules={[
                    () => ({
                      validator(rule, value) {
                        const phoneNumber = phoneNumberNational(value)
                        if (!value || (phoneNumber && phoneNumber.length === 14)) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error(formatMessage({ id: 'form.error.phone.invalid' })),
                        )
                      },
                    }),
                  ]}
                >
                  <MaskedInput
                    mask="(111) 111-1111"
                    placeholder={formatMessage({ id: 'form.placeholder.mobile' })}
                    size="large"
                  />
                </Form.Item>
              </div>

              <div className="col-md-6">
                <Form.Item
                  name="dob"
                  label={formatMessage({ id: 'form.label.dob' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <DatePicker onChange={onChange1} style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="doi"
                  label={formatMessage({ id: 'form.label.doi' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <DatePicker onChange={onChange2} style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="valid_till"
                  label={formatMessage({ id: 'form.label.valid_till' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <DatePicker onChange={onChange3} style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="aadhaar_no"
                  label={formatMessage({ id: 'form.label.aadhaar_no' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.aadhaar_no' })}
                    maxLength={12}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="address"
                  label={formatMessage({ id: 'form.label.address' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <TextArea
                    rows={4}
                    style={{ resize: 'none' }}
                    placeholder={formatMessage({ id: 'form.placeholder.address' })}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="status"
                  label={formatMessage({ id: 'form.label.user_status' })}
                  valuePropName="checked"
                >
                  <Switch
                    checkedChildren={formatMessage({ id: 'action.active' })}
                    unCheckedChildren={formatMessage({ id: 'action.inactive' })}
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item className="text-right">
              <Button type="primary" htmlType="submit" size="large" loading={vehicle?.loading}>
                {formatMessage({ id: 'action.Update' })}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(StoreSettings))
