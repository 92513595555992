import actions from './actions'

const initialState = {
  FuelExpenseInfodata: null,
  data: null,
  selectedData: null,
  fuelDocumentsList: null,

  searchFuel: null,
  displayAddDrawer: false,
  displayUpdateDrawer: false,
  displayRemoveModal: false,
  displayUploadModal: false,
  displayDocumentsModal: false,

  adding: false,
  updating: false,
  removing: false,
  uploading: false
}

export default function fuelReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_CREATE:
      return { ...state, displayAddDrawer: true }
    case actions.HIDE_CREATE:
      return { ...state, displayAddDrawer: false }
    case actions.SHOW_UPDATE:
      return { ...state, selectedData: action.payload.fuelInfo, displayUpdateDrawer: true }
    case actions.HIDE_UPDATE:
      return { ...state, selectedData: null, displayUpdateDrawer: false }
    case actions.SHOW_DELETE:
      return { ...state, selectedData: action.payload.fuelInfo, displayRemoveModal: true }
    case actions.HIDE_DELETE:
      return { ...state, displayRemoveModal: false }
    case actions.SHOW_UPLOAD:
      return { ...state, selectedData: action.payload.fuelInfo, displayUploadModal: true }
    case actions.HIDE_UPLOAD:
      return { ...state, displayUploadModal: false }
    case actions.SHOW_UPLOADED_DOCUMENTS:
      return { ...state, selectedData: action.payload.fuelInfo, displayDocumentsModal: true }
    case actions.HIDE_UPLOADED_DOCUMENTS:
      return { ...state, displayDocumentsModal: false }
    default:
      return state
  }
}
