import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Button, Form, Modal, Input, message } from 'antd'
import UserAction from 'redux/user/actions'

const mapStateToProps = ({ user }) => ({ user })

const ChangePassword = ({ visible, user, onCancel, loading, title, intl: { formatMessage } }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const onSubmit = values => {
    if (values?.newpassword !== values?.confirmnewpassword) {
      message.error('Password Not Matched')
    } else {
      const { ...formvalues } = values
      dispatch({
        type: UserAction.CHANGE_PASSWORD,
        payload: {
          updatedData: formvalues,
          _id: user?.userId,
        },
      })
    }
  }

  return (
    <Modal title={title} visible={visible} onCancel={onCancel} footer={null}>
      <Form form={form} layout="vertical" hideRequiredMark onFinish={onSubmit}>
        <Form.Item
          name="oldpassword"
          label={formatMessage({ id: 'form.label.oldpassword' })}
          rules={[
            { required: true, message: formatMessage({ id: 'form.error.password.required' }) },
            { min: 8, message: formatMessage({ id: 'form.error.password.min' }) },
            { max: 32, message: formatMessage({ id: 'form.error.password.max' }) },
          ]}
        >
          <Input.Password
            size="large"
            placeholder={`${formatMessage({ id: 'form.placeholder.oldpassword' })}`}
            maxLength={32}
          />
        </Form.Item>
        <Form.Item
          name="newpassword"
          label={formatMessage({ id: 'form.label.newpassword' })}
          rules={[
            { required: true, message: formatMessage({ id: 'form.error.password.required' }) },
            { min: 8, message: formatMessage({ id: 'form.error.password.min' }) },
            { max: 32, message: formatMessage({ id: 'form.error.password.max' }) },
          ]}
        >
          <Input.Password
            size="large"
            placeholder={`${formatMessage({ id: 'form.placeholder.newpassword' })}`}
            maxLength={32}
          />
        </Form.Item>
        <Form.Item
          name="confirmnewpassword"
          label={formatMessage({ id: 'form.label.confirmpassword' })}
          rules={[
            { required: true, message: formatMessage({ id: 'form.error.password.required' }) },
            { min: 8, message: formatMessage({ id: 'form.error.password.min' }) },
            { max: 32, message: formatMessage({ id: 'form.error.password.max' }) },
          ]}
        >
          <Input.Password
            size="large"
            placeholder={`${formatMessage({ id: 'form.placeholder.confirmpassword' })}`}
            maxLength={32}
          />
        </Form.Item>
        <Form.Item className="text-right">
          <Button type="primary" htmlType="submit" size="large" loading={loading}>
            {formatMessage({ id: 'action.change' })}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default connect(mapStateToProps)(injectIntl(ChangePassword))
