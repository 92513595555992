import React, { Suspense, useState } from 'react'
import { connect } from 'react-redux'
import { Table, Spin, Tag } from 'antd'
import { injectIntl } from 'react-intl'
import VehicleAction from 'redux/vehicle/actions'
import { getFormattedDate } from 'utils/parser'
import UpdateFuel from './UpdateFuel'


const mapStateToProps = ({ dispatch, vehicle }) => ({
  dispatch,
  vehicle,
})

const StoreSettings = ({ apiRes, vehicle, dispatch, intl: { formatMessage } }) => {
  const [page, setPage] = useState(1)

  // const onViewStoreClick = values => {
  //   window.open(values?.document_url)
  // }


  console.log("apiRes", apiRes)
  console.log("vehicle", vehicle)


  const showUpdate = values => {
    dispatch({
      type: VehicleAction.SHOW_FUEL_UPDATE,
      payload: {
        fuelInfo: values,
      },
    })
  }

  const hideUpdate = () => {
    dispatch({
      type: VehicleAction.HIDE_FUEL_UPDATE,
    })
  }



  const columns = [
    {
      title: formatMessage({ id: 'text.serialNo' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.fuel_variant' }),
      render: (text, record) => (
        <>
          <Tag
            color={(() => {
              switch (text?.fuel_variant) {
                case 'petrol':
                  return 'red'
                case 'diesel':
                  return 'green'
                default:
                  return 'blue'
              }
            })()}
            style={{ textTransform: 'capitalize' }}
          >
            {record?.fuel_variant}
          </Tag>
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.amount' }),
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => <>{record?.amount} INR</>,
    },
    {
      title: formatMessage({ id: 'text.kilometers' }),
      dataIndex: 'kilometers',
      key: 'kilometers',
      render: (text, record) => <>{record?.kilometers} KMS</>,
    },

    {
      title: formatMessage({ id: 'text.receipt_date' }),
      dataIndex: 'receipt_date',
      key: 'receipt_date',
      render: text => getFormattedDate(text),
    },
    {
      title: formatMessage({ id: 'text.ViewUrl' }),
      render: (text, record) => (
        <>
          <button type="button" className="btn btn-light" onClick={() => showUpdate(record)}>
            <i className="fe fe-eye mr-1" aria-hidden="true" />
            {formatMessage({ id: 'action.Edit' })}
          </button>
        </>
      ),
    },
  ]

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.fueldata' }),
  }

  const success = true

  let arrayCount=null
  if(apiRes?.length>0)
  {
    arrayCount=apiRes
  }

const totalSpent = arrayCount?.reduce(
    // eslint-disable-next-line
    function
      (previousValue, currentValue) {
      return {
        amount: Number(previousValue?.amount) + Number(currentValue?.amount),
      }
    });

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="mb-4">
            <div className="col-md-4 col-sm-6 col-xs-12" hidden={totalSpent?.amount === undefined}>
              <div className="rounded border border-gray-3 mb-3 p-3" style={{ backgroundColor: '#03349A' }}>
                <div className="text-white-5 font-weight-bold font-size-14" style={{ color: '#ffffff' }}>Total Expenses</div>
                <div
                  className={`${success ? 'text-white' : 'text-white-6'} font-weight-normal font-size-24`}
                  style={{ color: '#ffffff' }}
                >
                  {totalSpent?.amount} INR
                </div>
              </div>
            </div>
            <div className="kit__utils__table">
              <Suspense fallback={<Spin />}>
                {apiRes?.length >= 0 ? (
                  <Table
                    dataSource={apiRes}
                    columns={columns}
                    locale={locale}
                    rowKey="_id"
                    pagination={{
                      onChange(current) {
                        setPage(current)
                      },
                    }}
                  />
                ) : (
                  <Table
                    columns={columns}
                    locale={locale}
                    loading={{
                      indicator: (
                        <div>
                          <Spin />
                        </div>
                      ),
                    }}
                  />
                )}
              </Suspense>
            </div>
            <UpdateFuel
              visible={vehicle?.displayUpdateFuelDrawer}
              title={`Update Fuel - ${vehicle?.IndividualVehicleInfodata?.vehicle_reg_no}`}
              description={formatMessage({ id: 'text.updateFuel.description' })}
              onCancel={hideUpdate}
              loading={vehicle?.updatingFuel}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(StoreSettings))
