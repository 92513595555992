import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { injectIntl } from 'react-intl'
import axios from 'axios'
import { baseUrlInfo } from 'services/axios'
import { Button, Form, Select, Modal } from 'antd'
import { createStructuredSelector } from 'reselect'
import { selectDriver, selectedDriverdata } from 'redux/driver/selectors'
import { selectVehicle } from 'redux/vehicle/selectors'
import VehicleAction from 'redux/vehicle/actions'
import { documentType, ImageFormat } from 'utils/data'
import { selectSettings } from 'redux/settings/selectors'

const { Option } = Select

const mapStateToProps = () =>
  createStructuredSelector({
    driver: selectDriver,
    vehicle: selectVehicle,
    selectedDriver: selectedDriverdata,
    settings: selectSettings,
  })

const ChangeAccountManager = ({ visible, onCancel, loading, title, intl: { formatMessage } }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const apiBaseurl = baseUrlInfo

  const [fileInfo, setfileInfo] = useState(null)

  const onFileChange = event => {
    setfileInfo({ selectedFile: event.target.files[0] })
  }

  const path = window.location.pathname
  const userId = path.replace('/manage/vehicle/', '')

  const baseURL = apiBaseurl

  const onSubmit = values => {
    const formData = new FormData()
    formData.append('file', fileInfo.selectedFile, fileInfo.selectedFile.name)
    formData.append('type', values?.type)
    formData.append('image_format', values?.image_format)
    formData.append('vehicleId', userId)

    const response = axios.post(`${baseURL}/api/vehicle/upload-vehicle-docs`, formData)
    if (response) {
      dispatch({
        type: VehicleAction.HIDE_DOC_DRAWER,
      })
      dispatch({
        type: VehicleAction.LOAD_ALL,
      })
      dispatch({
        type: VehicleAction.LOAD_SINGLE_VEHICLE_DOCUMENT,
        payload: {
          vehicleId: userId,
        },
      })
    }
  }

  return (
    <Modal title={title} visible={visible} onCancel={onCancel} footer={null}>
      <Form form={form} layout="vertical" hideRequiredMark onFinish={onSubmit}>
        <Form.Item
          name="type"
          label={formatMessage({ id: 'form.label.type' })}
          rules={[{ required: true, message: formatMessage({ id: 'form.error.required' }) }]}
        >
          <Select
            showSearch
            placeholder={formatMessage({ id: 'form.placeholder.type' })}
            size="large"
          >
            {documentType?.map(o => (
              <Option key={o.key} value={o.key}>
                {o.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="image_format"
          label={formatMessage({ id: 'form.label.image_format' })}
          rules={[{ required: true, message: formatMessage({ id: 'form.error.required' }) }]}
        >
          <Select
            showSearch
            placeholder={formatMessage({ id: 'form.placeholder.image_format' })}
            size="large"
          >
            {ImageFormat?.map(o => (
              <Option key={o.key} value={o.key}>
                {o.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <input className="form-control" type="file" onChange={onFileChange} />
        <br />

        <Form.Item className="text-right">
          <Button type="primary" htmlType="submit" size="large" loading={loading}>
            {formatMessage({ id: 'action.upload' })}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default connect(mapStateToProps)(injectIntl(ChangeAccountManager))
