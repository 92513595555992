import { all, put, call, select, takeLatest } from 'redux-saga/effects'
import getMenuData from 'services/menu'
import actions from './actions'
import { selectSuperUserData } from '../user/selectors'

export function* SET_DATA() {
  const menuData = yield call(getMenuData)
  const SuperUserData = yield select(selectSuperUserData)
  if (!SuperUserData) menuData.splice(menuData.map(o => o.key).indexOf('manage-invoice'), 1)
  if (!SuperUserData) menuData.splice(menuData.map(o => o.key).indexOf('manage-payments'), 1)
  if (!SuperUserData) menuData.splice(menuData.map(o => o.key).indexOf('manage-enquiry'), 1)
  if (!SuperUserData) menuData.splice(menuData.map(o => o.key).indexOf('manage-renewals'), 1)
  if (!SuperUserData) menuData.splice(menuData.map(o => o.key).indexOf('manage-services'), 1)
  if (!SuperUserData) menuData.splice(menuData.map(o => o.key).indexOf('manage-accounts'), 1)
  if (!SuperUserData) menuData.splice(menuData.map(o => o.key).indexOf('manage-leads'), 1)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData,
    },
  })
}

export function* GET_DATA() {
  const menuData = yield call(getMenuData)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SET_DATA, SET_DATA),
    SET_DATA(), // run once on app load to fetch menu data
  ])
}
// export default function* rootSaga() {
//   yield all([takeLatest(actions.SET_DATA, SET_DATA), SET_DATA()])
// }
