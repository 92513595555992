const actions = {
  SET_STATE: 'fuel/SET_STATE',
  SHOW_CREATE: 'fuel/SHOW_CREATE',
  HIDE_CREATE: 'fuel/HIDE_CREATE',
  SHOW_UPDATE: 'fuel/SHOW_UPDATE',
  HIDE_UPDATE: 'fuel/HIDE_UPDATE',  
  SHOW_DELETE: 'fuel/SHOW_DELETE',
  HIDE_DELETE: 'fuel/HIDE_DELETE',
  SHOW_UPLOAD: 'fuel/SHOW_UPLOAD',
  HIDE_UPLOAD: 'fuel/HIDE_UPLOAD',
  SHOW_UPLOADED_DOCUMENTS: 'fuel/SHOW_UPLOADED_DOCUMENTS',
  HIDE_UPLOADED_DOCUMENTS: 'fuel/HIDE_UPLOADED_DOCUMENTS',

  SAMPLEUPLOAD: 'fuel/SAMPLEUPLOAD',



  LOAD_ALL: 'fuel/LOAD_ALL',
  REMOVE: 'fuel/REMOVE',
  CREATE_FUEL: 'fuel/CREATE_FUEL',
  UPDATE_FUEL: 'fuel/UPDATE_FUEL',
  GET_UPLOADED_DOCUMENTS: 'fuel/GET_UPLOADED_DOCUMENTS',
  REMOVE_DOCUMENT: 'fuel/REMOVE_DOCUMENT',
}

export default actions
