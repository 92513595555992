import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Input, Form, Button, Select, DatePicker, InputNumber, Switch } from 'antd'
import { injectIntl } from 'react-intl'
import VehicleAction from 'redux/vehicle/actions'
import { vehicleType, vehicleBodyType, fuelvariant, vehicleFor } from 'utils/data'
import MaskedInput from 'antd-mask-input'
import { phoneNumberNational, phoneNumberE164 } from 'utils/parser'
import CardTitle from 'components/app/common/CardTitle'
import moment from 'moment'

const { Option } = Select

const mapStateToProps = ({ dispatch, vehicle }) => ({
  dispatch,
  vehicle,
})

const StoreSettings = ({ dispatch, apiRes, vehicle, intl: { formatMessage } }) => {

  const [form] = Form.useForm()

  const AccountDatas = apiRes

  const ApiDatas = AccountDatas

  const onChange = (date, dateString) => {
    console.log(date, dateString)
  }

  const onChange2 = (date, dateString) => {
    console.log(date, dateString)
  }

  const onChange3 = (date, dateString) => {
    console.log(date, dateString)
  }

  const onChange4 = (date, dateString) => {
    console.log(date, dateString)
  }

  const onFinish = values => {
    const requestPayload = {
      vehicle_sno: Number(values?.vehicle_sno),
      vehicle_for: values?.vehicle_for,
      vehicle_type: values?.vehicle_type,
      vehicle_body_type: values?.vehicle_body_type,
      vehicle_reg_no: values?.vehicle_reg_no,
      vehicle_brand: values?.vehicle_brand,
      vehicle_model: values?.vehicle_model,
      vehicle_month_year: `${values?.vehicle_month_year.format('YYYY-MM')}-01`,
      fuel_variant: values?.fuel_variant,
      seating_capacity: values?.seating_capacity,
      chasis_no: values?.chasis_no,
      engine_no: values?.engine_no,
      owner_name: values?.owner_name,
      first_fc: values?.first_fc.format('YYYY-MM-DD'),
      mobile_number: phoneNumberE164(values.mobile_number),
      rto_name: values?.rto_name,
      rto_city: values?.rto_city,
      rto_state: values?.rto_state,
      insurer_name: values?.insurer_name,
      policy_no: values?.policy_no,
      validity_from: values?.validity_from.format('YYYY-MM-DD'),
      validity_till: values?.validity_till.format('YYYY-MM-DD'),
      status: values?.status,
    }
    dispatch({
      type: VehicleAction.UPDATE,
      payload: {
        updatedData: requestPayload,
        vehicleId: ApiDatas?._id,
      },
    })
  }

  useEffect(() => {
    dispatch({
      type: VehicleAction.LOAD_ALL,
    })
  }, [dispatch])

  useEffect(() => {
    if (AccountDatas) {
      form.setFieldsValue({
        vehicle_sno: ApiDatas?.vehicle_sno,
        vehicle_for: ApiDatas?.vehicle_for,
        vehicle_reg_no: ApiDatas?.vehicle_reg_no,
        vehicle_body_type: ApiDatas?.vehicle_body_type,
        vehicle_type: ApiDatas?.vehicle_type,
        owner_name: ApiDatas?.owner_name,
        vehicle_brand: ApiDatas?.vehicle_brand,
        vehicle_model: ApiDatas?.vehicle_model,
        mobile_number: phoneNumberNational(ApiDatas?.mobile_number),
        first_fc: moment(ApiDatas?.first_fc),
        vehicle_month_year: moment(ApiDatas?.vehicle_month_year),
        fuel_variant: ApiDatas?.fuel_variant,
        seating_capacity: ApiDatas?.seating_capacity,
        chasis_no: ApiDatas?.chasis_no,
        engine_no: ApiDatas?.engine_no,
        rto_name: ApiDatas?.rto_name,
        rto_city: ApiDatas?.rto_city,
        rto_state: ApiDatas?.rto_state,
        insurer_name: ApiDatas?.insurer_name,
        policy_no: ApiDatas?.policy_no,
        validity_from: moment(ApiDatas?.validity_from),
        validity_till: moment(ApiDatas?.validity_till),
        status: ApiDatas?.status,
      })
    } // eslint-disable-next-line
  }, [form, AccountDatas])

  return (
    <div>
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        form={form}
        initialValues={{
          vehicle_sno: ApiDatas?.vehicle_sno,
          vehicle_for: ApiDatas?.vehicle_for,
          vehicle_reg_no: ApiDatas?.vehicle_reg_no,
          vehicle_body_type: ApiDatas?.vehicle_body_type,
          vehicle_type: ApiDatas?.vehicle_type,
          owner_name: ApiDatas?.owner_name,
          vehicle_brand: ApiDatas?.vehicle_brand,
          vehicle_model: ApiDatas?.vehicle_model,
          mobile_number: phoneNumberNational(ApiDatas?.mobile_number),
          first_fc: moment(ApiDatas?.first_fc),
          vehicle_month_year: moment(ApiDatas?.vehicle_month_year),
          fuel_variant: ApiDatas?.fuel_variant,
          seating_capacity: ApiDatas?.seating_capacity,
          chasis_no: ApiDatas?.chasis_no,
          engine_no: ApiDatas?.engine_no,
          rto_name: ApiDatas?.rto_name,
          rto_city: ApiDatas?.rto_city,
          rto_state: ApiDatas?.rto_state,
          insurer_name: ApiDatas?.insurer_name,
          policy_no: ApiDatas?.policy_no,
          validity_from: moment(ApiDatas?.validity_from),
          validity_till: moment(ApiDatas?.validity_till),
          status: ApiDatas?.status,
        }}
      >
        <div className="card">
          <div className="card-body">
            <CardTitle title={formatMessage({ id: 'text.VehicleDetails' })} />
            <div className="row">
              <div className="col-md-6">
                <Form.Item
                  name="vehicle_for"
                  label={formatMessage({ id: 'form.label.vehicle_for' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={formatMessage({ id: 'form.placeholder.vehicle_for' })}
                    size="large"
                  >
                    {vehicleFor?.map(o => (
                      <Option key={o.key} value={o.key}>
                        {o.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="vehicle_type"
                  label={formatMessage({ id: 'form.label.vehicle_type' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={formatMessage({ id: 'form.placeholder.vehicle_type' })}
                    size="large"
                  >
                    {vehicleType?.map(o => (
                      <Option key={o.key} value={o.key}>
                        {o.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="vehicle_body_type"
                  label={formatMessage({ id: 'form.label.vehicle_body_type' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={formatMessage({ id: 'form.placeholder.vehicle_body_type' })}
                    size="large"
                  >
                    {vehicleBodyType?.map(o => (
                      <Option key={o.key} value={o.key}>
                        {o.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="vehicle_reg_no"
                  label={formatMessage({ id: 'form.label.vehicle_reg_no' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.vehicle_reg_no' })}
                    maxLength={11}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="vehicle_sno"
                  label={formatMessage({ id: 'form.label.vehicle_sno' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.vehicle_sno' })}
                    maxLength={2}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="vehicle_brand"
                  label={formatMessage({ id: 'form.label.vehicle_brand' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.vehicle_brand' })}
                    maxLength={64}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="vehicle_model"
                  label={formatMessage({ id: 'form.label.vehicle_model' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.vehicle_model' })}
                    maxLength={64}
                  />
                </Form.Item>
              </div>

              <div className="col-md-6">
                <Form.Item
                  name="vehicle_month_year"
                  label={formatMessage({ id: 'form.label.vehicle_month_year' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <DatePicker onChange={onChange} picker="month" style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="fuel_variant"
                  label={formatMessage({ id: 'form.label.fuel_variant' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={formatMessage({ id: 'form.placeholder.fuel_variant' })}
                    size="large"
                  >
                    {fuelvariant?.map(o => (
                      <Option key={o.key} value={o.key}>
                        {o.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div className="col-md-6">
                <Form.Item
                  name="seating_capacity"
                  label={formatMessage({ id: 'form.label.seating_capacity' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.seating_capacity' })}
                    maxLength={2}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="chasis_no"
                  label={formatMessage({ id: 'form.label.chasis_no' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.chasis_no' })}
                    maxLength={20}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="engine_no"
                  label={formatMessage({ id: 'form.label.engine_no' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.engine_no' })}
                    maxLength={20}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <CardTitle title={formatMessage({ id: 'text.ownerDetails' })} />
            <div className="row">
              <div className="col-md-6">
                <Form.Item
                  name="owner_name"
                  label={formatMessage({ id: 'form.label.owner_name' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.owner_name' })}
                    maxLength={64}
                  />
                </Form.Item>
              </div>

              <div className="col-md-6">
                <Form.Item
                  name="first_fc"
                  label={formatMessage({ id: 'form.label.first_fc' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <DatePicker style={{ width: '100%' }} onChange={onChange2} />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="mobile_number"
                  label={formatMessage({ id: 'form.label.phoneoptional' })}
                  rules={[
                    () => ({
                      validator(rule, value) {
                        const phoneNumber = phoneNumberNational(value)
                        if (!value || (phoneNumber && phoneNumber.length === 14)) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error(formatMessage({ id: 'form.error.phone.invalid' })),
                        )
                      },
                    }),
                  ]}
                >
                  <MaskedInput
                    mask="(111) 111-1111"
                    placeholder={formatMessage({ id: 'form.placeholder.mobile' })}
                    size="large"
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <CardTitle title={formatMessage({ id: 'text.RTODetails' })} />
            <div className="row">
              <div className="col-md-6">
                <Form.Item
                  name="rto_name"
                  label={formatMessage({ id: 'form.label.rto_name' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.rto_name' })}
                    maxLength={20}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="rto_city"
                  label={formatMessage({ id: 'form.label.rto_city' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.rto_city' })}
                    maxLength={20}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="rto_state"
                  label={formatMessage({ id: 'form.label.rto_state' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.rto_state' })}
                    maxLength={20}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <CardTitle title={formatMessage({ id: 'text.InsuranceDetails' })} />
            <div className="row">
              <div className="col-md-6">
                <Form.Item
                  name="insurer_name"
                  label={formatMessage({ id: 'form.label.insurer_name' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.insurer_name' })}
                    maxLength={75}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="policy_no"
                  label={formatMessage({ id: 'form.label.policy_no' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.policy_no' })}
                    maxLength={25}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="validity_from"
                  label={formatMessage({ id: 'form.label.validity_from' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <DatePicker style={{ width: '100%' }} onChange={onChange3} />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="validity_till"
                  label={formatMessage({ id: 'form.label.validity_till' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <DatePicker style={{ width: '100%' }} onChange={onChange4} />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="status"
                  label={formatMessage({ id: 'form.label.vehicleStatus' })}
                  valuePropName="checked"
                >
                  <Switch
                    checkedChildren={formatMessage({ id: 'action.active' })}
                    unCheckedChildren={formatMessage({ id: 'action.inactive' })}
                  />
                </Form.Item>
              </div>
              <div className="col-md-12">
                <Form.Item className="text-right">
                  <Button type="primary" htmlType="submit" size="large" loading={vehicle?.loading}>
                    {formatMessage({ id: 'action.update' })}
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(StoreSettings))
